/** @jsx jsx */
import * as React from 'react';
import { jsx } from '@emotion/core';
//@ts-ignore
import { PlusIcon } from 'components/ui/icons';
import TextField from 'components/ui/TextField';
import Select from 'react-select';
import CurrencyField from 'components/ui/CurrencyField';
import { useNysTaxSearch } from '../NysTaxSearchContext';
import {
  buildingClassCustomStyles,
  customStyles,
  minus,
  plusButton,
  sectionDivStyles,
} from '../TaxObjectsAndStyles';
import AddBuildingClassModal from './AddBuildingClassModal';
import DateInputPaste from 'components/ui/DateInputPaste';
import ConvertToTitleCaseElement from 'components/forms/ConvertTitleCaseElement';
import { Checkbox } from 'semantic-ui-react';

function PropertySection() {
  const {
    property,
    sectionOpen,
    propertyTaxVariables,
    condo,
    setSectionOpen,
    handlePropertyChange,
    setPropertyTaxVariables,
    setCondo,
    buildingClassModalOpen,
    setBuildingClassModalOpen,
    buildingClassTypes,
    getBuildingTypes,
    dimensions,
    handleSelectSchool,
    client,
  } = useNysTaxSearch();

  const [landAssessedNa, setLandAssessedNa] = React.useState(false);
  const [totalAssessedNa, setTotalAssessedNa] = React.useState(false);

  return (
    <div style={sectionDivStyles} id="Property">
      {sectionOpen === 'Property' ? (
        <React.Fragment>
          <div
            style={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ fontSize: 20 }}>Property Information</div>
            <button style={plusButton} onClick={() => setSectionOpen('')}>
              {sectionOpen === 'Property' ? <span style={minus} /> : <PlusIcon />}
            </button>
          </div>
          <div>County: {property.county}</div>
          <div style={{ display: 'flex', marginTop: 24 }}>
            <div>
              <TextField
                width="296px"
                label={`Swis Code:  (${property.municipality})`}
                onChange={(e: any) => handlePropertyChange('swisCode', e.target.value)}
                value={property.swisCode}
              />
            </div>
            <div style={{ marginLeft: 16 }}>
              <ConvertToTitleCaseElement
                onIconClick={newStr => {
                  handlePropertyChange('assessTo', newStr);
                }}
                originalString={property.assessTo}
              >
                <TextField
                  width="296px"
                  label="Assess To:"
                  onChange={(e: any) => handlePropertyChange('assessTo', e.target.value)}
                  value={property.assessTo}
                />
              </ConvertToTitleCaseElement>
            </div>
          </div>
         {[54, 208].includes(client) && <div>
            <TextField
              width="100%"
              label="Owner Address:"
              onChange={(e: any) => handlePropertyChange('ownerAddress', e.target.value)}
              value={property.ownerAddress}
            />
          </div>}
          <div style={{ marginTop: 16, display: 'flex' }}>
            <div style={{ marginRight: 16 }}>
              <ConvertToTitleCaseElement
                onIconClick={newStr => {
                  handlePropertyChange('schoolDistrict', newStr);
                }}
                originalString={property.schoolDistrict}
              >
                <TextField
                  width="296px"
                  label="School District:"
                  onChange={(e: any) => handlePropertyChange('schoolDistrict', e.target.value)}
                  value={property.schoolDistrict}
                  //onBlur={(e: any) => handleSelectSchool(e.target.value)}
                />
              </ConvertToTitleCaseElement>
            </div>
            <div style={{ marginRight: 16 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ marginBottom: 8, display: 'inline-block' }}>Building Class</span>
                <div
                  onClick={() => setBuildingClassModalOpen(true)}
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}
                >
                  Add Building Class
                </div>
              </div>
              <Select
                name="buildingClass"
                label="Building Class"
                options={buildingClassTypes}
                styles={buildingClassCustomStyles}
                onChange={(buildingClass: any) =>
                  setPropertyTaxVariables({ ...propertyTaxVariables, buildingClass })
                }
                value={buildingClassTypes.find(
                  bc =>
                    bc.label === propertyTaxVariables.buildingClass.label &&
                    bc.value === propertyTaxVariables.buildingClass.value
                )}
              />
              {buildingClassModalOpen && (
                <AddBuildingClassModal
                  onClose={() => setBuildingClassModalOpen(false)}
                  refreshData={getBuildingTypes}
                  state={'NYS'}
                />
              )}
            </div>
          </div>
          <div style={{ marginTop: 16, display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <TextField
                name="propertySize"
                width="296px"
                label="Property Size/Acreage"
                onChange={(e: any) =>
                  setPropertyTaxVariables({ ...propertyTaxVariables, propertySize: e.target.value })
                }
                value={propertyTaxVariables.propertySize}
              />
              <div>Dimensions: {dimensions}</div>
            </div>
            <div style={{ marginLeft: 16, position: 'relative' }}>
              <CurrencyField
                showFreeFormProp={isNaN(parseFloat(propertyTaxVariables?.landAssessment))}
                name="landAssessment"
                width="296px"
                label="Land Assessment"
                onChange={(landAssessment: any) => {
                  if (landAssessment == undefined || landAssessment === '') {
                    landAssessment = 0;
                  }
                  setPropertyTaxVariables({ ...propertyTaxVariables, landAssessment });
                }}
                value={propertyTaxVariables.landAssessment}
              />
              <div style={{ position: 'absolute', right: 0, top: 0 }}>
                <Checkbox
                  checked={landAssessedNa || propertyTaxVariables.landAssessment?.toString().includes('N/A')}
                  onChange={(e, { checked }) => {
                    if (checked) {
                      setPropertyTaxVariables({ ...propertyTaxVariables, landAssessment: 'N/A' });
                    } else {
                      setPropertyTaxVariables({
                        ...propertyTaxVariables,
                        landAssessment: propertyTaxVariables.landAssessment.replace('N/A', ''),
                      });
                    }
                    setLandAssessedNa(checked);
                  }}
                  label={'N/A'}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                marginTop: 16,
                marginRight: 16,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              {propertyTaxVariables.buildingClass.isVacantLand ? null : (
                <div style={{ position: 'relative' }}>
                  <CurrencyField
                    showFreeFormProp={isNaN(parseFloat(propertyTaxVariables?.totalAssessment))}
                    name="totalAssessment"
                    width="296px"
                    label="Total Assessment"
                    onChange={(totalAssessment: any) => {
                      if (totalAssessment === undefined || totalAssessment === '') {
                        totalAssessment = 0;
                      }
                      setPropertyTaxVariables({ ...propertyTaxVariables, totalAssessment });
                    }}
                    value={propertyTaxVariables.totalAssessment}
                  />
                  <div style={{ position: 'absolute', right: 0, top: 0 }}>
                    <Checkbox
                      checked={
                        totalAssessedNa || propertyTaxVariables.totalAssessment?.toString().includes('N/A')
                      }
                      onChange={(e, { checked }) => {
                        if (checked) {
                          setPropertyTaxVariables({ ...propertyTaxVariables, totalAssessment: 'N/A' });
                        } else {
                          setPropertyTaxVariables({
                            ...propertyTaxVariables,
                            totalAssessment: propertyTaxVariables.totalAssessment?.replace('N/A', ''),
                          });
                        }
                        setTotalAssessedNa(checked);
                      }}
                      label={'N/A'}
                    />
                  </div>
                </div>
              )}
            </div>
            {property.county === 'Nassau' ? (
              <div style={{ marginTop: 16, marginRight: 16 }}>
                <TextField
                  name="condo"
                  width="296px"
                  label="Condo"
                  onChange={(condo: any) => setCondo(condo.target.value)}
                  value={condo}
                />
              </div>
            ) : null}
          </div>
          {[121, 288, 362, 420].includes(client) && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 32 }}>
              <div>Sale Date</div>
              <DateInputPaste
                styles={{ height: 48, width: 296 }}
                width={296}
                value={property.saleDate}
                onChange={date => handlePropertyChange('saleDate', date)}
              />
            </div>
          )}
        </React.Fragment>
      ) : (
        <div
          onClick={() => setSectionOpen('Property')}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h2>Property Information</h2>
          <span style={plusButton}>
            <PlusIcon />
          </span>
        </div>
      )}
    </div>
  );
}

export default PropertySection;
