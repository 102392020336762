import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { AddNote, DeleteNote, DisabledMinus, Minus } from '../../../HelperDocuments/icons';
import colors from 'css/colors';
import { Search, AssignObj, SearchCategories, Assignee } from 'components/new-order-entry/types';
import AssignDropdown from './AssignDropdown';
import TextArea from 'components/forms/ui/TextArea';
import QuillWysiwyg from 'components/ui/QuillWysiwyg';

const customStyles = {
  container: (provided: any, state: any) => ({
    ...provided,
    marginTop: 8,
    marginLeft: 16,
    marginRight: 10,
    width: 272,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: `1px solid ${colors.grey}`,
    //   boxShadow: state.isFocused && '1px solid #ff5252',
    //   border: state.isFocused && '1px solid #ff5252',
    height: 48,
    //   '&:hover': {
    //     border: state.isFocused && '1px solid #ff5252',
    //   },
  }),
};
const IncludedSearches = styled.div`
  width: 376px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 2px 6px 6px 6px;
  margin-left: 26px;
  min-height: 48px;
`;
const SearchType = styled.div`
  background-color: #e5e5ea;
  border-radius: 5px;
  padding: 8px 12px;
  display: inline-block;
  cursor: pointer;
  margin-top: 4px;
  margin-left: 2px;
`;
interface Props {
  category: SearchCategories;
  assignObj: AssignObj;
  minusSearch: (index: number, category: string, s: Search) => void;
  index: number;
  searches: any;
  isFirstType?: boolean;
  updateAssignNoteSearchesAssign: (field: 'assignee' | 'orderNote' | 'showAdd', value: any) => void;
  selectedAssignee: Assignee;
  orderNote: string;
  searchTypes: Search[];
}

const Assign = (props: Props) => {
  const [assignees, setAssignees] = useState([]);
  const [category, setCategory] = useState('');
  const [openNote, setOpenNote] = useState(false);
  const [tempNote, setTempNote] = useState(props.assignObj.orderNote || '');
  const getAssignees = async () => {
    const usersPromise = axios.get('/api/users/forassignment');
    const vendorsPromise = axios.get('/api/vendors/getVendorsWithContacts');
    const statusesPromise = axios.get('/api/searches/GetSearchStatusTypes');
    const [users, vendors, statuses] = await Promise.all([usersPromise, vendorsPromise, statusesPromise]);
    // var filteredStatuses = statuses.data.filter(
    //   (x: any) => x.name == 'In Progress' || x.name == 'Data Entry' || x.name == 'Proofing'
    // );
    setAssignees([
      { category: 'Employees', items: [...users.data] },
      { category: 'Vendors', items: [...vendors.data] },
      { category: 'Statuses', items: [...statuses.data] },
    ]);
  };
  useEffect(() => {
    getAssignees();
  }, []);

  const onClickRemoveNote = () => {
    props.updateAssignNoteSearchesAssign('orderNote', '');
  };
  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <AssignDropdown
            employees={(assignees.find(a => a.category === 'Employees') || {}).items || []}
            vendors={
              (assignees.find(a => a.category === 'Vendors') || {}).items?.map((v: any) => ({
                ...v,
                isVendor: true,
              })) || []
            }
            statuses={
              (assignees.find(a => a.category === 'Statuses') || {}).items?.map((s: any) => ({
                ...s,
                isStatus: true,
              })) || []
            }
            type={category}
            assignees={assignees}
            searches={props.searches}
            addAssigneeClick={(e: any) => {
              props.updateAssignNoteSearchesAssign('assignee', e);
            }}
            isFirstType={props.isFirstType}
            category={props.category}
            selectedEmployee={props.assignObj.assignee || ''}
            searchTypes={props.searchTypes}
          />
          {openNote && (
            <React.Fragment>
              <div
                style={{
                  height: 243,
                  width: 272,
                  marginLeft: 78,
                  marginTop: 4,
                  marginBottom: 20,
                  border: '1px solid black',
                }}
              >
                <QuillWysiwyg
                  value={props.assignObj.orderNote || tempNote}
                  maxheight={243}
                  onBlur={note => {
                    props.updateAssignNoteSearchesAssign('orderNote', note);
                  }}
                  onKeyDown={_ => !props.assignObj.showAdd && props.updateAssignNoteSearchesAssign('showAdd', true)}
                  onChange={note => {
                    setTempNote(note);
                  }}
                  mentionValues={(
                    (assignees.find(a => a.category === 'Employees') || {}).items || []
                  ).map((a: any) => ({ id: a.id, value: a.name }))}
                  setHeightOfContainer={true}
                />
              </div>
              {props.assignObj.showAdd && (
                <div
                  onClick={() => props.updateAssignNoteSearchesAssign('showAdd', false)}
                  style={{
                    color: '#8E8E93',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    zIndex: 5000
                  }}
                >
                  Add
                </div>
              )}
            </React.Fragment>
            // @ts-ignore
            // <TextArea
            //   styles={{ width: 272, marginLeft: 78, marginTop: 4, marginBottom: 20 }}
            //   placeholder="Add a note..."
            //   onBlur={(e: any) => {
            //     props.updateAssignNoteSearchesAssign('orderNote', e.target.value);
            //   }}
            // />
          )}
        </div>
        {openNote ? (
          <DeleteNote
            style={{ cursor: 'pointer', marginTop: 24, marginLeft: 8 }}
            onClick={() => {
              setOpenNote(!openNote);
              onClickRemoveNote();
            }}
          />
        ) : (
          <AddNote
            style={{ cursor: 'pointer', marginTop: 24, marginLeft: 8 }}
            onClick={() => setOpenNote(!openNote)}
          />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
        <IncludedSearches>
          {props.assignObj.searchTypes.map((s, index, searchTypes) => {
            return (
              <SearchType>
                <div>
                  <span style={{ paddingRight: 8 }}>{s.type || s.name}</span>
                  {searchTypes.length > 1 ? (
                    <Minus onClick={() => props.minusSearch(props.index, props.category, s)} />
                  ) : (
                    <DisabledMinus />
                  )}
                </div>
              </SearchType>
            );
          })}
        </IncludedSearches>
      </div>
    </React.Fragment>
  );
};

export default Assign;
