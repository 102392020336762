import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { DropdownOption, SearchesAssign, Property, OrderEntryName, File, SubmitionError } from './types';
import { useHistory } from 'react-router-dom';
import { boolean } from 'yup';
import convertToFileDownload from '../FileDownloads';

interface Props {
  children: React.ReactNode;
}
const rejex = /[\s][^a-zA-Z0-9_,&]+/gi;

interface ContextType {
  selectedClient: DropdownOption;
  setSelectedClient: (e: DropdownOption) => void;
  titleNumber: string;
  contactEmailReceiver: string;
  setContactEmailReceiver: React.Dispatch<React.SetStateAction<string>>;
  priority: DropdownOption;
  setTitleNumber: React.Dispatch<React.SetStateAction<string>>;
  setPriority: React.Dispatch<DropdownOption>;
  searchesAssignObj: SearchesAssign;
  setSearchesAssignObj: React.Dispatch<React.SetStateAction<SearchesAssign>>;
  properties: Property[];
  setProperties: React.Dispatch<React.SetStateAction<Property[]>>;
  orderEntryNames: OrderEntryName[];
  setOrderEntryNames: React.Dispatch<React.SetStateAction<OrderEntryName[]>>;
  submitOrder: () => void;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setIsPurchase: React.Dispatch<React.SetStateAction<boolean>>;
  isPurchase: boolean;
  submitting: boolean;
  errors: SubmitionError;
  specialOrderSearches: string[];
  dataTraceMunis: string[];
  searchTypesToDataTrace: string[];
  searchTypesToRequested: string[];
  openSellerErrorModal: boolean;
  setOpenSellerErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultInitialNameType = { value: 1, label: 'Seller' };
const defaultBorrowerType = { value: 1, label: 'Borrower' };
const defaultSellertype = { value: 2, label: 'Seller' };

const OrderEntryContext = createContext<ContextType | undefined>(undefined);

export const OrderEntryProvider = (props: Props) => {
  const [superRushClients, setSuperRushClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState<DropdownOption>({ value: null, label: '' });
  const [titleNumber, setTitleNumber] = useState('');
  const [priority, setPriority] = useState<DropdownOption>({ value: null, label: '' });
  const [searchesAssignObj, setSearchesAssignObj] = useState<SearchesAssign>({});
  const [properties, setProperties] = useState<Property[]>([]);
  const [contactEmailReceiver, setContactEmailReceiver] = useState<string>('');
  const [orderEntryNames, setOrderEntryNames] = useState<OrderEntryName[]>([
    {
      name: '',
      type: defaultInitialNameType,
      checkBankruptcy: false,
      checkPatriot: false,
      checkCogs: false,
      checkFranchise: false,
      checkArticles: false,
    },
  ]);
  const [files, setFiles] = useState<File[]>([]);
  const [isPurchase, setIsPurchase] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<SubmitionError>({});
  const [openSellerErrorModal, setOpenSellerErrorModal] = useState(false);

  useEffect(() => {
    const isSuperRushClient = superRushClients.map(src => src.name).includes(selectedClient.label);
    const defaultPriority = {
      value: isSuperRushClient ? 2 : 0,
      label: isSuperRushClient ? 'Priority: Super Rush' : 'Priority: Regular',
    };
    setPriority(defaultPriority);
  }, [selectedClient]);

  useEffect(() => {
    getSuperRushClients();
  }, []);

  const getSuperRushClients = async () => {
    const { data } = await axios.get('/api/clients/getSuperRushclients');
    setSuperRushClients(data);
  };
  const specialOrderSearches = [
    'Fire Violation Search',
    'Air Resources Search',
    'Survey Locate',
    'Fuel Oil Permit Search',
  ];
  const dataTraceMunis = ['Hempstead', 'North Hempstead', 'Oyster Bay'];
  const searchTypesToDataTrace = ['CO', 'HB', 'ER', 'HD', 'LM', 'SW', 'SL', 'HS'];
  const searchTypesToRequested = ['SR', 'PERALT', 'GE', 'FV'];
  const { push } = useHistory();

  const validateForm = () => {
    const errors: SubmitionError = {};
    if (!titleNumber) {
      errors.titleNumber = 'Title Number Required';
    }
    if (!selectedClient?.value) {
      errors.selectedClient = 'Client is Required';
    }
    const oeNamesCheck = orderEntryNames.find(oen => oen.name) !== undefined;
    const noteCheck =
      Object.values(searchesAssignObj)?.find(sao => sao.find(sa => sa.orderNote)) !== undefined;
    if (!oeNamesCheck && !noteCheck) {
      errors.names = 'Needs at least 1 seller or note';
      setOpenSellerErrorModal(true);
    }
    if (!properties.length) {
      errors.properties = 'Needs at least 1 Property';
    }
    const unselectedAssignee = Object.values(searchesAssignObj).find(sa => sa.find(s => !s.assignee?.id));
    if (unselectedAssignee) {
      errors.assignee = 'All searches have to be assigned';
    }
    const unsavedNotes = Object.values(searchesAssignObj).find(sa => sa.find(s => s.showAdd));
    if (unsavedNotes) {
      errors.assigneeNotesUnsaved = 'There are unsaved notes please hit Add by all notes unsaved';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0 ? null : errors;
  };
  const submitOrder = async () => {
    const err = validateForm();
    if (!err) {
      const order = {
        titleNumber,
        priority: priority.value,
        selectedClient: selectedClient.value,
        searchesAssignObj: Object.values(searchesAssignObj)
          ?.reduce((pre, cur) => pre.concat(cur), [])
          .map(sao => ({
            ...sao,
            searchTypes: sao.searchTypes.map(st => ({ ...st, accutitleNumber: sao.accutitleNumber })),
          })),
        files,
        properties,
        orderEntryNames: orderEntryNames
          .filter(oen => oen.name?.trim())
          .map(oen => ({
            ...oen,
            name: oen.name.trim().replace(rejex, ' '),
            type:
              oen.type.label === 'Buyer'
                ? defaultBorrowerType
                : oen.type.label === 'Seller'
                ? defaultSellertype
                : oen.type,
          })),
        isPurchase,
        contactEmailReceiver,
      };
      setSubmitting(true);
      const { status, data } = await axios.post('/api/orderEntry/add', order);
      setSubmitting(false);
      if (status === 200) {
        localStorage.removeItem('selectedProperties');
        push(`/orders/new-order/success/${data.orderId}${data.error ? '/true' : ''}`);
        data.msgFiles?.forEach((msgFile: any) => {
          convertToFileDownload(msgFile.base64, msgFile.fileName);
        });
      }
    }
  };
  // useEffect(() => {

  // },[orderEntryNames])

  return (
    <OrderEntryContext.Provider
      value={{
        selectedClient,
        setSelectedClient,
        titleNumber,
        setTitleNumber,
        priority,
        setPriority,
        searchesAssignObj,
        setSearchesAssignObj,
        properties,
        setProperties,
        orderEntryNames,
        setOrderEntryNames,
        submitOrder,
        files,
        setFiles,
        isPurchase,
        setIsPurchase,
        submitting,
        errors,
        specialOrderSearches,
        dataTraceMunis,
        searchTypesToDataTrace,
        searchTypesToRequested,
        openSellerErrorModal,
        setOpenSellerErrorModal,
        contactEmailReceiver,
        setContactEmailReceiver,
      }}
    >
      {props.children}
    </OrderEntryContext.Provider>
  );
};

export function useOrderEntry() {
  const context = useContext(OrderEntryContext);
  return context;
}
