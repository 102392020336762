import TextField from 'components/ui/TextField';
import React, { Fragment } from 'react';
import { SearchesAssign, SearchCategories, AssignObj } from '../types';
import Assign from './Assign';

interface PropTypes {
  searchesAssignObj: SearchesAssign;
  handleMinusSearch: any;
  updateAssignNoteSearchesAssign: (
    key: string,
    index: number,
    field: 'assignee' | 'orderNote' | 'accutitleNumber'| 'showAdd',
    value: any
  ) => void;
  isNj: boolean;
}
const RenderAssign = (props: PropTypes) => {
  const keys = Object.keys(props.searchesAssignObj);
  if (keys.length === 0) {
    return <Fragment />;
  }

  return (
    <React.Fragment>
      <div style={{ fontWeight: 600, marginTop: 24 }}>Assign</div>
      <div style={{ display: 'flex', marginTop: 24 }}>
        <div style={{width: props.isNj ? 912 : 512,  display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'inline-block', color: '#C7C7CC', marginLeft: 78 }}>Assignee</div>
          <div style={{ display: 'inline-block', color: '#C7C7CC' }}>Included Searches</div>
         {props.isNj && <div style={{ display: 'inline-block', color: '#C7C7CC', marginLeft:10 }}>Accutitle Ref Number</div>}
        </div>
      </div>
      {keys.map((k, key) => {
        if (!props.searchesAssignObj[k][0]) {
          return <div />;
        }
        return (
          <div key={key + k}>
            <div style={{ marginTop: 8, width: 80, marginBottom: -36 }}>{k}</div>
            {props.searchesAssignObj[k as SearchCategories].map((sa: AssignObj, index) => {
              return (
                <div style={{ display: 'flex', marginTop: 8 }} key={index}>
                  <Assign
                    category={k as SearchCategories}
                    assignObj={sa}
                    minusSearch={props.handleMinusSearch}
                    index={index}
                    searches={props.searchesAssignObj}
                    isFirstType={index === 0}
                    updateAssignNoteSearchesAssign={(field: 'assignee' | 'orderNote' | 'showAdd', value: any) =>
                      props.updateAssignNoteSearchesAssign(k, index, field, value)
                    }
                    selectedAssignee={props.searchesAssignObj[k][index].assignee}
                    orderNote={props.searchesAssignObj[k][index].orderNote}
                    searchTypes={sa.searchTypes}
                  />
                 {props.isNj && <TextField height={48} styles={{marginTop:8, marginLeft:8}} onChange={(e)=>props.updateAssignNoteSearchesAssign(k, index, 'accutitleNumber', e.target.value)
                    }/>}
                </div>
              );
            })}

          </div>
        );
      })}
    </React.Fragment>
  );
};

export default RenderAssign;
