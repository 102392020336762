import colors from 'css/colors';
import { css } from 'emotion';
import React from 'react';
import * as types from './types';
import moment from 'moment';
export const statusOptions = [
  { value: 'Paid', label: 'Paid', specialStatus: false },
  { value: 'Open', label: 'Open', specialStatus: false },
  { value: 'Open + Penalty', label: 'Open + Penalty', specialStatus: false },
  { value: 'Open / Payoff: $', label: 'Open / Payoff: $', specialStatus: true },
  { value: 'Open (No penalty thru )', label: 'Open (No penalty thru )', specialStatus: true },
  { value: 'Open (includes penalty thru )', label: 'Open (includes penalty thru )', specialStatus: true },
  { value: 'Open / See Arrears', label: 'Open / See Arrears', specialStatus: true },
  { value: 'Open (Payable to County)', label: 'Open (Payable to County)', specialStatus: true },
  {
    value: 'Open + Penalty (Payable to County)',
    label: 'Open + Penalty (Payable to County)',
    specialStatus: true,
  },
  {
    value: 'Open + Past Due / Total Due: $',
    label: 'Open + Past Due / Total Due: $',
    specialStatus: true,
  },
  {
    value: 'Partially Paid / Balance Open: $',
    label: 'Partially Paid / Balance Open: $',
    specialStatus: true,
  },
  { value: 'Re-levied onto ', label: 'Re-levied onto ', specialStatus: true },
  { value: 'To Follow', label: 'To Follow', specialStatus: false },
  { value: 'N/A', label: 'N/A', specialStatus: false },
  { value: 'Other', label: 'Other', specialStatus: false },
];

export const waterSewerTaxTypes = [
  'Water/Sewer',
  'Water',
  'Sewer',
  'Water/Sanitation',
  'Sanitation',
  'Water/Sewer & Sanitation',
  'Garbage',
  'Sewer Bond',
  'User Fee',
  'Sewer Rent',
];
export const arrearsOptions = ['No Arrears', 'Has Arrears', 'Arrears if any to follow', 'Arrears to follow'];
export const waterDescriptionOptions = [
  'Water/Sewer',
  'Water',
  'Sewer',
  'Water/Sanitation',
  'Sanitation',
  'Water/Sewer & Sanitation',
  'Garbage',
  'Sewer Bond',
  'User Fee',
  'Inactive Account',
  'Sewer Rent',
  'Other',
].map((val, idx) => ({ value: val, label: val }));

export const waterTaxTypes = [
  'Private',
  'Public',
  'Not Available',
  'Common Meter (Condo)',
  'Vacant Land',
  'To Follow',
  'No Account Listed',
  'Public when Improved',
  'Not Searched',
  'No Account Established Yet',
  'Included in Taxes',
  'See separate sewer search provided',
  'Other',
].map((val, idx) => ({ value: val, label: val }));

export const defaultWaterTax: (
  county: string,
  dragAndDropCounter: number,
  clientName: string
) => types.WaterTax = (county, dragAndDropCounter, clientName) => ({
  fullAmountStatus: { value: '', label: '' },
  fullTaxAmount: null,
  fullAmountDueDate: new Date(),
  installments: [],
  taxPeriodStartingMonth: 0,
  yearApplicable: new Date().getFullYear(),
  taxDescriptionType: waterDescriptionOptions[0],
  type: ['Nassau'].includes(county)
    ? waterTaxTypes.find(type => {
        if (clientName?.toLowerCase().includes('madison title')) {
          return type.label === 'Not Searched';
        }

        return type.label === 'Not Available';
      })
    : ['Suffolk'].includes(county)
    ? waterTaxTypes.find(type => type.label === 'Other')
    : waterTaxTypes[0],
  useUnits: false,
  unitNumber: '',
  multipleInstallments: false,
  dragAndDropId: dragAndDropCounter,
  typeOther: ['Suffolk'].includes(county)
    ? 'Not Searched - Homeowner should present bill/receipt at closing'
    : '',
  taxDescriptionOtherType: '',
  additionalWaterInformation: '',
});

export const washingtonVilleWater: types.WaterTax = {
  fullAmountStatus: { value: '', label: '' },
  fullTaxAmount: null,
  fullAmountDueDate: new Date(),
  installments: [],
  taxPeriodStartingMonth: 0,
  yearApplicable: new Date().getFullYear(),
  taxDescriptionType: waterDescriptionOptions[0],
  type: waterTaxTypes.find(type => type.label === 'Other'),
  useUnits: false,
  unitNumber: '',
  multipleInstallments: false,
  dragAndDropId: 1,
  typeOther:
    'Not Available / Village policy is to only provide Water/Sewer information directly to homeowner.',
  taxDescriptionOtherType: '',
  additionalWaterInformation: '',
};

export const defaultWaterTaxForEgov: (
  county: string,
  dragAndDropCounter: number,
  clientName: string
) => types.WaterTax = (county, dragAndDropCounter, clientName) => ({
  fullAmountStatus: { value: '', label: '' },
  fullTaxAmount: null,
  fullAmountDueDate: new Date(),
  installments: [],
  taxPeriodStartingMonth: 0,
  yearApplicable: new Date().getFullYear(),
  taxDescriptionType: waterDescriptionOptions[0],
  type: ['Nassau', 'Suffolk'].includes(county)
    ? waterTaxTypes.find(type => {
        if (clientName?.toLowerCase().includes('madison title')) {
          return type.label === 'Not Searched';
        }

        return type.label === 'Not Available';
      })
    : { label: '', value: '' },
  useUnits: false,
  unitNumber: '',
  multipleInstallments: false,
  dragAndDropId: dragAndDropCounter,
  taxDescriptionOtherType: '',
  additionalWaterInformation: '',
});

export const createWaterTaxItem: (
  taxCollected: types.TaxCollected,
  dragAndDropCounter: number,
  county: string,
  clientName: string
) => types.WaterTax = (taxCollected, dragAndDropCounter, county, clientName) => {
  const firstInstallmentMonth = taxCollected.installmentMonths[0]?.month || taxCollected.taxPeriodStartingMonth
  return {
    fullAmountStatus: { value: '', label: '' },
    fullTaxAmount: null,
    fullAmountDueDate: new Date(),
    installments: taxCollected.installmentMonths.map(im => {
      return {
        amount: '',
        date: `${getYear(firstInstallmentMonth + 1, im.month + 1)}-${im.month + 1}-${im.day}`,
        status: { value: '', label: '' },
        statusNote: '',
        year: {
          value: getYear(firstInstallmentMonth + 1, im.month + 1),
          label: getYear(firstInstallmentMonth + 1, im.month + 1).toString(),
        },
      };
    }),
    taxPeriodStartingMonth: taxCollected.taxPeriodStartingMonth,
    yearApplicable: new Date().getFullYear(),
    taxDescriptionType:
      waterDescriptionOptions.find(
        w =>
          taxCollected.taxDescriptionType?.type !== 'Water' &&
          w.label === taxCollected.taxDescriptionType?.type
      ) || waterDescriptionOptions[0],
    type: ['Nassau'].includes(county)
      ? waterTaxTypes.find(type => {
          if (clientName?.toLowerCase().includes('madison title')) {
            return type.label === 'Not Searched';
          }

          return type.label === 'Not Available';
        })
      : ['Suffolk'].includes(county)
      ? waterTaxTypes.find(type => type.label === 'Other')
      : waterTaxTypes[0],
    useUnits: false,
    unitNumber: '',
    multipleInstallments: taxCollected.installmentMonths.length > 1 && !taxCollected.areInstallmentsOptional,
    dragAndDropId: dragAndDropCounter,
    taxDescriptionOtherType: '',
    additionalWaterInformation: '',
    typeOther: ['Suffolk'].includes(county)
      ? 'Not Searched - Homeowner should present bill/receipt at closing'
      : '',
    datePeriod:
      taxCollected.installmentMonths.length > 1 ? getDatePeriod(taxCollected.taxPeriodStartingMonth + 1, firstInstallmentMonth + 1) : '',
  };
};

export const months = [
  {
    label: 'January',
    value: 0,
  },
  {
    label: 'February',
    value: 1,
  },
  {
    label: 'March',
    value: 2,
  },
  {
    label: 'April',
    value: 3,
  },
  {
    label: 'May',
    value: 4,
  },
  {
    label: 'June',
    value: 5,
  },
  {
    label: 'July',
    value: 6,
  },
  {
    label: 'August',
    value: 7,
  },
  {
    label: 'September',
    value: 8,
  },
  {
    label: 'October',
    value: 9,
  },
  {
    label: 'November',
    value: 10,
  },
  {
    label: 'December',
    value: 11,
  },
];
export const propertyTaxStatusOptions = [
  { value: 'Paid', label: 'Paid', specialStatus: false },
  { value: 'Open', label: 'Open', specialStatus: false },
  { value: 'Open + Penalty', label: 'Open + Penalty', specialStatus: false },
  { value: 'Open / Payoff: $', label: 'Open / Payoff: $', specialStatus: true },
  { value: 'Open (No penalty thru )', label: 'Open (No penalty thru )', specialStatus: true },
  { value: 'Open (includes penalty thru )', label: 'Open (includes penalty thru )', specialStatus: true },
  { value: 'Open / See Arrears', label: 'Open / See Arrears', specialStatus: true },
  { value: 'Open (Payable to County)', label: 'Open (Payable to County)', specialStatus: true },
  {
    value: 'Open + Penalty (Payable to County)',
    label: 'Open + Penalty (Payable to County)',
    specialStatus: true,
  },
  {
    value: 'Open + Past Due / Total Due: $',
    label: 'Open + Past Due / Total Due: $',
    specialStatus: true,
  },
  {
    value: 'Partially Paid / Balance Open: $',
    label: 'Partially Paid / Balance Open: $',
    specialStatus: true,
  },
  { value: 'Re-levied onto ', label: 'Re-levied onto ', specialStatus: true },
  { value: 'To Follow', label: 'To Follow', specialStatus: false },
  { value: 'N/A', label: 'N/A', specialStatus: false },
  { value: 'Other', label: 'Other', specialStatus: false },
];
export const days = [...Array(31).keys()].map(i => {
  return { label: (i + 1).toString(), value: i + 1 };
});
export const defaultPriorItem: types.SplitTaxCollected = {
  additionalInformation: '',
  taxDescriptionType: { containsWater: false, id: -1, isCounty: false, listOrder: -1, type: '' },
  fullTaxAmount: null,
  id: Math.random(),
  singleInstallment: { date: { day: null, year: null, month: null } },
  installments: [
    {
      amount: null,
      status: { label: undefined, value: undefined },
      date: {
        month: null,
        day: { label: undefined, value: undefined },
        year: { label: undefined, value: undefined },
      },
      statusNote: '',
    },
  ],
  paidInMultipleInstallments: false,
  fullAmountStatus: { label: undefined, value: undefined },
  fullAmountStatusNote: '',
  exemptions: [],
  areInstallmentsOptional: true,
  installmentFee: null,
  isPriorItem: true,
  isInstallmentFeeDollarAmount: true,
  installmentMonths: [],
  taxAuthorityId: null,
  taxAuthorityName: '',
  taxPeriodStartingMonth: null,
  yearApplicable: null,
  hasLiens: false,
  hasRochesterEmbellishments: false,
  rochesterEmbellishments: [],
};

export const exemptionTypes = [
  //'STAR (School Tax Relief)',
  'Basic Star',
  'Enhanced Star',
  'Senior Star',
  'Aged',
  'Disabled',
  'Veterans',
  'War Vet',
  'Disabled Vet',
  'Combat Vet',
  'Wholly Exempt',
  'Non profit',
  'Religious',
  'Charity',
  'Volunteer Fire & Amb.',
  'Agri. Dist.',
  'Condo',
].map((e, index) => ({ label: e, value: e }));

export const defaultProperty: types.Property = {
  address: '',
  addressLine2: '',
  assessTo: '',
  block: '',
  canDelete: false,
  county: '',
  countyOnly: false,
  district: '',
  id: null,
  includeMunicipalSearches: false,
  isResidential: false,
  isValidated: false,
  lot: '',
  municipality: '',
  municipalityId: null,
  municipalityType: '',
  order: null,
  orderId: null,
  schoolDistrict: '',
  searches: null,
  section: '',
  state: '',
  subMunicipality: '',
  subMunicipalityId: null,
  subMunicipalityType: '',
  swisCode: '',
  undeterminedLocality: null,
  zip: '',
  rpadPropertyId: 0,
  sbl: '',
};

export const defaultSplitTaxCollected: types.SplitTaxCollected = {
  areInstallmentsOptional: false,
  exemptions: [],
  fullAmountStatus: null,
  fullTaxAmount: '',
  fullAmountStatusNote: '',
  taxAuthorityId: null,
  taxAuthorityName: '',
  taxDescriptionType: {
    containsWater: false,
    id: null,
    isCounty: null,
    listOrder: null,
    type: null,
  },
  taxPeriodStartingMonth: null,
  yearApplicable: null,
  additionalInformation: '',
  id: null,
  installmentFee: null,
  installmentMonths: [],
  installments: [],
  isInstallmentFeeDollarAmount: false,
  paidInMultipleInstallments: false,
  singleInstallment: {
    date: {
      day: {
        label: null,
        value: null,
      },
      month: {
        label: null,
        value: null,
      },
    },
  },
  hasRochesterEmbellishments: false,
  rochesterEmbellishments: [],
};
export const defaultPropertyTaxVariables: types.PropertyTaxVariables = {
  landAssessment: '',
  totalAssessment: '',
  buildingClass: {
    value: '',
    label: '',
    isVacantLand: false,
  },
  buildingClassTypes: [],
  propertySize: '',
};

export const installmentStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 16,
  alignItems: 'center',
};
export const priorItemInstallmentStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 24,
  alignItems: 'center',
  width: 526,
};

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    // marginRight: 150,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export const customStylesForEmbellishments = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

export const buildingClassCustomStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 295,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export const textBoxStyles = css({
  marginTop: 8,
  width: 605,
  height: 100,
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  resize: 'none',
  padding: 24,
  ':focus': {
    outline: 'none',
  },
});

export const parentParcelTextBoxStyles = css({
  marginTop: 8,
  width: 605,
  height: 192,
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  resize: 'none',
  padding: 24,
  ':focus': {
    outline: 'none',
  },
});

export const arrearsTextBoxStyles = {
  marginTop: 8,
  width: '100%',
  height: 192,
  //backgroundColor: colors.greyOrder,
  borderRadius: 5,
  resize: 'vertical',
  padding: 24,
  ':focus': {
    outline: 'none',
  },
};

export const sectionDivStyles = {
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  padding: 40,
  marginBottom: 32,
  width: 688,
  marginRight: 100,
};

export const plusButton = {
  color: colors.redLight,
  height: 22,
  cursor: 'pointer',
  '&:hover': {
    transition: 'all .2s',
    color: colors.red,
    span: {
      borderColor: colors.red,
    },
  },
};

export const minus = {
  borderTop: `2px solid ${colors.redLight}`,
  display: 'block',
  width: 18,
  cursor: 'pointer',
};
export const waterForm = {
  backgroundColor: colors.greyOrder,
  borderRadius: 5,
  padding: 16,
  marginTop: 16,
  ':firstOfType': {
    marginTop: 0,
  },
};

export const dateStyles = {
  width: 295,
  caretColor: colors.red,
  backgroundColor: colors.white,
  input: {
    width: '100%',
    cursor: 'pointer',
    ':focus': {
      outline: 'none',
    },
  },
};

export const waterCustomStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 275,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
export const overlayStyles = css({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  bottom: 0,
  height: '100%',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 50,
});
export const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const baseExemption: any = {
  type: undefined,
  savings: undefined,
};

export const baseInstallment: types.Installment = {
  amount: null,
  date: {
    day: { label: null, value: null },
    month: { label: null, value: null },
    year: { label: null, value: null },
  },
  status: { label: null, value: null },
  statusNote: null,
};
export const embellishmentsOptions = [
  'Hazard Sidewalk Replacement',
  'Roadway Snow Plow',
  'Street Maintenance',
  'Sidewalk Snow Plow',
].map((e, index) => ({ label: e, value: e }));

const getYear = (periodStartMonth: number, installmentMonth: number) => {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  if (periodStartMonth === 1) {
    return currentYear;
  }
  if (month < periodStartMonth) {
    return installmentMonth < periodStartMonth ? currentYear : currentYear - 1;
  }

  return installmentMonth < periodStartMonth ? currentYear + 1 : currentYear;
};

const getDatePeriod = (periodStartMonth: number, startInstallmentMonth: number ) => {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  if (periodStartMonth === 1  ) {
    return `1/1-12/31 ${month < startInstallmentMonth ? currentYear - 1: currentYear}`;
  }
  const periodEndMonth = moment(`${periodStartMonth}/1/${currentYear}`).add(1, 'year').subtract(1, 'day');
  const periodMonths = `${periodStartMonth}/1-${periodEndMonth.format('M/DD')}`;
  const periodYears =
    month < periodStartMonth ? `${currentYear - 1}/${currentYear}` : `${currentYear}/${currentYear + 1}`;
  return `${periodMonths} ${periodYears}`;
};
